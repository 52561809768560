import React, { useState, useEffect } from "react";
import { Box, Typography, ButtonBase, Button, Grid } from "@mui/material";
import Layout from "../Util/Layout";
import { Link } from "react-router-dom";
import { DroneIcon, CalculatorIcon, ToolkitIcon, BoatSensorIcon, NetworkIcon, WebIcon } from '../../Assets/SVGs';

const HomePage = () => {
  const [role, setRole] = useState("Software Engineer");
  const [opacity, setOpacity] = useState(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const roles = [
    "Martial Artist ",
    "Cornhusker ",
    "Tinkerer ",
    "Software Engineer ",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setOpacity(0); // Fade out
      setTimeout(() => {
        const currentRoleIndex = roles.indexOf(role);
        const nextRoleIndex = (currentRoleIndex + 1) % roles.length;
        setRole(roles[nextRoleIndex]);
        setOpacity(1); // Fade in
      }, 500); // Adjust this duration as needed
    }, 3000); // Changes every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [role, roles]);

  const handleRoleChange = () => {
    setOpacity(0); // Fade out
    setTimeout(() => {
      const currentRoleIndex = roles.indexOf(role);
      const nextRoleIndex = (currentRoleIndex + 1) % roles.length;
      setRole(roles[nextRoleIndex]);
      setOpacity(1); // Fade in
    }, 500); // Adjust this duration as needed
  };
  return (
    <Layout>
      <Box textAlign="center" my={5}>
        <Typography variant="h2" gutterBottom>
          Hi, my name is <strong>Nick Colleran</strong>
        </Typography>
        <Typography variant="h4" color="textSecondary" paragraph>
          And I am a{" "}
          <Button
            style={{
              fontSize: "inherit",
              fontWeight: "inherit",
              fontFamily: "inherit",
              color: "#007bff", // Change to your preferred color
              padding: 0,
              minWidth: 0,
              background: "none",
              boxShadow: "none",
              transition: "opacity 0.5s",
              opacity,
            }}
            onClick={handleRoleChange}
          >
            {role}
          </Button>{" "}
          out of Lincoln, NE
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component="a" // This tells Material-UI to render this component as an `<a>` tag.
          href="/Nick_Colleran_2024_Resume.pdf"
          download="Nick_Colleran_2024_Resume.pdf" // Optional: specify a filename for the downloaded file
          style={{ marginBottom: '20px' }} 
        >
          Download My Resume
        </Button>
        <Typography variant="h7" paragraph>
          I have been working with software for nearly a decade, giving me a
          strong foundation in multiple programming languages and frameworks. I
          spend my free time practicing martial arts, preparing for amateur
          bodybuilding shows, working on personal projects, and enjoying the
          outdoors. Currently, I am working with ALA Engineering in Lincoln NE. I 
          am developing vision processing models for an autonomy stack, and working on 
          many more autonomy based projects. Check out a few of my more prominent{" "}
          <strong>software-related projects below</strong>, or reach out by
          filling out the contact form and I will be in touch shortly.
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {projects.map((project, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <ButtonBase
              component={Link}
              to={project.link}
              style={{
                display: "block",
                textAlign: "initial",
                transition: "transform 0.3s ease-in-out",
                width: "100%",
              }}
              sx={{
                "&:hover": {
                  transform: "scale(1.05)", // Scales up the box on hover
                },
              }}
            >
              <Box bgcolor="primary.light" p={3}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '20px', height: '20px', marginRight: '10px' }}>{project.icon}</div>
                  <Typography variant="h6">{project.title}</Typography>
              </div>

                <Typography variant="body1">{project.description}</Typography>
              </Box>
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
};

const projects = [
  {
    title: "Mission Review",
    description:
      "This AWS serverless application allows for easy drone flight data sharing and troubleshooting by leveraging the AWS tool-suite. There is a big focus on data ingestion and manipulation.",
    link: "mission-review",
    icon: <DroneIcon />,
  },
  {
    title: "AI Premium Calculation",
    description:
      "This project envisions an automated data ingestion system that taps into telematics data via APIs, and uses a risk-analysis model to recommend commercial insurance premiums nationwide.",
    link: "ai-premium-calculation",
    icon: <CalculatorIcon />,
  },
  {
    title: "InvenTree Toolkit",
    description:
      "An inventory management tool suite that automates everyday tasks for administrative workers. It taps into the open source InvenTree APIs to save the company over $1k per week.",
    link: "inventree-toolkit",
    icon: <ToolkitIcon />,
  },
  {
    title: "A.W.C.S.D.R",
    description:
      'Short for "Aerial Water Collaborative Sensor Deployment and Recovery" - An unmanned vessel to be deployed by drones in the arctic with the intention of monitoring beaver life.',
    link: "water-collaborative",
    icon: <BoatSensorIcon />,
  },
  {
    title: "Class Matching App",
    description:
      "This application helps users keep track of social and academic connections. Users can view the classes that they share with their friends, making it easier to schedule study sessions or group projects.",
    link: "class-matching-app",
    icon: <NetworkIcon />,
  },
  {
    title: "Personal Website",
    description:
      "This is version 3 of my portfolio website. Each redesign represents a different era of my programming skills and styles. The current version is driven by React using Material UI standards.",
    link: "personal-website",
    icon: <WebIcon />,
  },
];

export default HomePage;
